// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, getDoc, } from "firebase/firestore";
import { getStorage , } from "firebase/storage";
import { getDatabase } from 'firebase/database';


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDLWn8LcLjOBMZdutzdB6cxOKxp3YwIBVM",
  authDomain: "gpa-ichalkaranji.firebaseapp.com",
  projectId: "gpa-ichalkaranji",
  storageBucket: "gpa-ichalkaranji.appspot.com",
  messagingSenderId: "1071699152972",
  appId: "1:1071699152972:web:a995d9be9d9d6be20264e9",
  measurementId: "G-0P5F5R62BQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const database = getDatabase(app);
export {  db, storage,database, setDoc, doc, getDoc };
