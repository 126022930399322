import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';
import { Table, Input, Button, Modal, Form } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { FilePdfOutlined, FileExcelOutlined, PlusOutlined, PrinterOutlined, DeleteOutlined } from '@ant-design/icons';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import './UserAdmin.css';

const UserAdmin = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newUser, setNewUser] = useState({
    uniqueId: '', name: '', email: '', contactNumber: '', username: '', whatsappNo: '', qualification: '', otherQualification: '', otherSpecialization: '', gender: '', dob: '', address1: '', address2: ''
  });
  const [searchText, setSearchText] = useState(''); // State for search input

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userCollection = collection(db, 'users');
        const userSnapshot = await getDocs(userCollection);
        const userList = userSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setUsers(userList);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchUsers();
  }, []);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  // Function to highlight matched text
  // Function to highlight matched text with undefined check
const highlightText = (text) => {
  if (!text) return ''; // Return empty string if text is undefined
  if (!searchText) return text; // If there's no search text, return the original text
  const regex = new RegExp(`(${searchText})`, 'gi');
  const parts = text.split(regex);
  return parts.map((part, index) =>
    part.toLowerCase() === searchText.toLowerCase() ? (
      <span key={index} style={{ backgroundColor: '#E6E6FA', fontWeight: 'bold' }}>{part}</span>
    ) : (
      part
    )
  );
};


  // Filter users based on search text
  const filteredUsers = users.filter(user =>
    Object.values(user).some(val =>
      String(val).toLowerCase().includes(searchText.toLowerCase())
    )
  );

  const handleAddUser = async () => {
    try {
      const userRef = await addDoc(collection(db, 'users'), newUser);
      setUsers([...users, { id: userRef.id, ...newUser }]);
      setNewUser({});
      setIsModalVisible(false);
    } catch (error) {
      setError('Failed to add user');
    }
  };

  const handleDeleteUser = async (userId) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this user?',
      onOk: async () => {
        try {
          await deleteDoc(doc(db, 'users', userId));
          setUsers(users.filter((user) => user.id !== userId));
        } catch (error) {
          setError('Failed to delete user');
        }
      },
    });
  };

  const handleExportPDF = () => {
    const doc = new jsPDF();
    doc.text('User Data', 20, 10);
    const columns = ["Unique ID", "Name", "Email", "Contact Number", "Username", "WhatsApp No.", "Qualification", "Other Qualification", "Other Specialization", "Gender", "DOB", "Address 1", "Address 2"];
    const rows = users.map(user => [
      user.uniqueId, user.name, user.email, user.contactNumber, user.username, user.whatsappNo, user.qualification, user.otherQualification, user.otherSpecialization, user.gender, user.dob, user.address1, user.address2
    ]);
    doc.autoTable({ head: [columns], body: rows });
    doc.save('users.pdf');
  };

  const handleExportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(users);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
    XLSX.writeFile(workbook, 'users.xlsx');
  };

  // Define table columns with the highlightText function
  const columns = [
    { title: 'Unique ID', dataIndex: 'uniqueId', render: (text) => highlightText(text) },
    { title: 'Name', dataIndex: 'name', render: (text) => highlightText(text) },
    { title: 'Email', dataIndex: 'email', render: (text) => highlightText(text) },
    { title: 'Contact Number', dataIndex: 'contactNumber', render: (text) => highlightText(text) },
    { title: 'Username', dataIndex: 'username', render: (text) => highlightText(text) },
    { title: 'WhatsApp No.', dataIndex: 'whatsappNo', render: (text) => highlightText(text) },
    { title: 'Qualification', dataIndex: 'qualification', render: (text) => highlightText(text) },
    { title: 'Other Qualification', dataIndex: 'otherQualification', render: (text) => highlightText(text) },
    { title: 'Other Specialization', dataIndex: 'otherSpecialization', render: (text) => highlightText(text) },
    { title: 'Gender', dataIndex: 'gender', render: (text) => highlightText(text) },
    { title: 'DOB', dataIndex: 'dob', render: (text) => highlightText(text) },
    { title: 'Address 1', dataIndex: 'address1', render: (text) => highlightText(text) },
    { title: 'Address 2', dataIndex: 'address2', render: (text) => highlightText(text) },
    {
      title: 'Actions',
      render: (text, record) => (
        <Button onClick={() => handleDeleteUser(record.id)} icon={<DeleteOutlined />} danger />
      ),
    },
  ];

  return (
    <div className="user-admin-section">
      <div className="header">
        <Input
          placeholder="Search"
          prefix={<SearchOutlined />}
          style={{ width: '300px' }}
          value={searchText}
          onChange={handleSearch}
        />
        <Button icon={<PrinterOutlined />} onClick={() => window.print()}>Print</Button>
        <Button icon={<FilePdfOutlined />} onClick={handleExportPDF}>Export PDF</Button>
        <Button icon={<FileExcelOutlined />} onClick={handleExportExcel}>Export Excel</Button>
      </div>

      <div className="table-container">
        <Table
          columns={columns}
          dataSource={filteredUsers} // Display filtered data
          rowKey="id"
          loading={loading}
          pagination={{ pageSize: 8 }}
        />
      </div>

      <Modal
        title="Add New User"
        visible={isModalVisible}
        onOk={handleAddUser}
        onCancel={() => setIsModalVisible(false)}
      >
         <Form layout="vertical" >
          <Form.Item label="Unique ID">
            <Input value={newUser.uniqueId} onChange={(e) => setNewUser({ ...newUser, uniqueId: e.target.value })} />
          </Form.Item>
          <Form.Item label="Name">
            <Input value={newUser.name} onChange={(e) => setNewUser({ ...newUser, name: e.target.value })} />
          </Form.Item>
          <Form.Item label="Email">
            <Input value={newUser.email} onChange={(e) => setNewUser({ ...newUser, email: e.target.value })} />
          </Form.Item>
          <Form.Item label="Contact Number">
            <Input value={newUser.contactNumber} onChange={(e) => setNewUser({ ...newUser, contactNumber: e.target.value })} />
          </Form.Item>
          <Form.Item label="Username">
            <Input value={newUser.username} onChange={(e) => setNewUser({ ...newUser, username: e.target.value })} />
          </Form.Item>
          <Form.Item label="WhatsApp No">
            <Input value={newUser.whatsappNo} onChange={(e) => setNewUser({ ...newUser, whatsappNo: e.target.value })} />
          </Form.Item>
          <Form.Item label="Qualification">
            <Input value={newUser.qualification} onChange={(e) => setNewUser({ ...newUser, qualification: e.target.value })} />
          </Form.Item>
          <Form.Item label="Other Qualification">
            <Input value={newUser.otherQualification} onChange={(e) => setNewUser({ ...newUser, otherQualification: e.target.value })} />
          </Form.Item>
          <Form.Item label="Other Specialization">
            <Input value={newUser.otherSpecialization} onChange={(e) => setNewUser({ ...newUser, otherSpecialization: e.target.value })} />
          </Form.Item>
          <Form.Item label="Gender">
            <Input value={newUser.gender} onChange={(e) => setNewUser({ ...newUser, gender: e.target.value })} />
          </Form.Item>
          <Form.Item label="DOB">
            <Input type="date" value={newUser.dob} onChange={(e) => setNewUser({ ...newUser, dob: e.target.value })} />
          </Form.Item>
          <Form.Item label="Address 1">
            <Input value={newUser.address1} onChange={(e) => setNewUser({ ...newUser, address1: e.target.value })} />
          </Form.Item>
          <Form.Item label="Address 2">
            <Input value={newUser.address2} onChange={(e) => setNewUser({ ...newUser, address2: e.target.value })} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UserAdmin;
