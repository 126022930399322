import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "./AdminSidebar.css";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { FaUser, FaChartBar, FaAd, FaDonate, FaCalendarAlt, FaClipboardList } from "react-icons/fa";

const AdminSidebar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`sidebar ${isOpen ? "open" : "collapsed"}`}>
            <div className="top-section">
                <div className="menu-btn" onClick={toggleSidebar}>
                    {isOpen ? (
                        <IoIosArrowBack className="arrow-icon" />
                    ) : (
                        <IoIosArrowForward className="arrow-icon" />
                    )}
                </div>
            </div>
            <h2 className="sidebar-title">{isOpen && "Admin Panel"}</h2>
            <ul className="nav-menu">
                <Link to="/" style={{ textDecoration: "none" }}>
                    <li className="nav-item">
                        <FaUser className="icon" />
                        {isOpen && <span>Users</span>}
                    </li>
                </Link>
                <Link to="/Activity" style={{ textDecoration: "none" }}>
                    <li className="nav-item">
                        <FaChartBar className="icon" />
                        {isOpen && <span>Activity</span>}
                    </li>
                </Link>
                <Link to="/Advertisement" style={{ textDecoration: "none" }}>
                    <li className="nav-item">
                        <FaAd className="icon" />
                        {isOpen && <span>Advertisement</span>}
                    </li>
                </Link>
                <Link to="/Donation" style={{ textDecoration: "none" }}>
                    <li className="nav-item">
                        <FaDonate className="icon" />
                        {isOpen && <span>Donation</span>}
                    </li>
                </Link>
                <Link to="/UpcomingEvent" style={{ textDecoration: "none" }}>
                    <li className="nav-item">
                        <FaCalendarAlt className="icon" />
                        {isOpen && <span>Upcoming Events</span>}
                    </li>
                </Link>
                <Link to="/CMErequests" style={{ textDecoration: "none" }}>
                    <li className="nav-item">
                        <FaClipboardList className="icon" />
                        {isOpen && <span>CME Registrations</span>}
                    </li>
                </Link>
            </ul>
        </div>
    );
};

export default AdminSidebar;
