// src/components/ActivityAdmin.js
import React, { useEffect, useState } from 'react';
import { storage } from '../firebase';
import { ref, listAll, getDownloadURL, deleteObject, uploadBytesResumable } from 'firebase/storage';
import './ActivityAdmin.css';

const ActivityAdmin = () => {
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    const storageRef = ref(storage, 'ActivityImages');
    const result = await listAll(storageRef);
    const urlPromises = result.items.map((imageRef) => getDownloadURL(imageRef));
    const urls = await Promise.all(urlPromises);
    setImages(urls);
    setLoading(false);
  };

  const deleteImage = async (url) => {
    const imageRef = ref(storage, url);
    await deleteObject(imageRef);
    fetchImages();
  };

  const uploadImage = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setUploading(true);
    const storageRef = ref(storage, `ActivityImages/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
        console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        console.error('Upload failed:', error);
        setUploading(false);
      },
      () => {
        setUploading(false);
        setUploadProgress(0);
        fetchImages();
      }
    );
  };

  return (
    <div className="activity-admin">
      <h2>Manage Activity Images</h2>
      <div className="upload-section">
        <label className="custom-file-upload">
          <input type="file" onChange={uploadImage} disabled={uploading} />
          {uploading ? 'Uploading...' : 'Upload Image'}
        </label>
        {uploading && <progress value={uploadProgress} max="100" />}
      </div> {loading ? (
        <div className="loading-spinner">
          <div className="spinner"></div>
        </div>
      ) : (
      <div className="image-grid">
        {images.map((url, index) => (
          <div key={index} className="image-item">
            <img src={url} alt={`Activity ${index + 1}`} />
            <button className="delete-button" onClick={() => deleteImage(url)}>Delete</button>
          </div>
        ))}
      </div>)}
    </div>
  );
};

export default ActivityAdmin;
