// src/components/DonationAdmin.js
import React, { useEffect, useState } from 'react';
import { storage } from '../firebase';
import { ref, listAll, getDownloadURL, deleteObject, uploadBytesResumable } from 'firebase/storage';
import './DonationAdmin.css';

const DonationAdmin = () => {
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    setLoading(true);
    const storageRef = ref(storage, 'Donations');
    const result = await listAll(storageRef);
    const urlPromises = result.items.map((imageRef) => getDownloadURL(imageRef));
    const urls = await Promise.all(urlPromises);
    setImages(urls);
    setLoading(false);
  };

  const deleteImage = async (url) => {
    const imageRef = ref(storage, url);
    await deleteObject(imageRef);
    fetchImages();
  };

  const uploadImage = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setUploading(true);
    const storageRef = ref(storage, `Donations/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
        console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        console.error('Upload failed:', error);
        setUploading(false);
      },
      () => {
        setUploading(false);
        setUploadProgress(0);
        fetchImages();
      }
    );
  };

  return (
    <div className="donation-admin">
      <h2>Manage Donation Images</h2>
      <div className="upload-section">
        <label className="custom-file-upload">
          <input type="file" onChange={uploadImage} disabled={uploading} />
          {uploading ? 'Uploading...' : 'Upload Image'}
        </label>
        {uploading && <progress value={uploadProgress} max="100" />}
      </div>
      {loading ? (
        <div className="loading-spinner">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="image-grid">
          {images.map((url, index) => (
            <div key={index} className="image-item" onClick={() => setSelectedImage(url)}>
              <img src={url} alt={`Donation ${index + 1}`} />
              <button className="delete-button" onClick={(e) => {
                e.stopPropagation();
                deleteImage(url);
              }}>Delete</button>
            </div>
          ))}
        </div>
      )}
      {selectedImage && (
        <div className="modal" onClick={() => setSelectedImage(null)}>
          <div className="modal-content">
            <img src={selectedImage} alt="Enlarged" />
          </div>
        </div>
      )}
    </div>
  );
};

export default DonationAdmin;
