import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, query, where, onSnapshot, doc, updateDoc } from "firebase/firestore";
import { Tabs, Tab, Box, Typography, TextField, Button } from '@mui/material';
import './CMERequest.css';

function CMERequest() {
  const [requests, setRequests] = useState([]);
  const [paymentAmounts, setPaymentAmounts] = useState({});
  const [tabIndex, setTabIndex] = useState(0);  // Track active tab

  useEffect(() => {
    const fetchRequests = () => {
      const q = query(collection(db, 'requests'));
      return onSnapshot(q, (snapshot) => {
        setRequests(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      });
    };

    const unsubscribe = fetchRequests();
    return () => unsubscribe();
  }, []);

  const handleAcceptRequest = async (requestId) => {
    const amount = paymentAmounts[requestId];
    if (amount) {
      const requestDocRef = doc(db, 'requests', requestId);
      await updateDoc(requestDocRef, {
        paymentAmount: amount,
        status: 'Approved',
      });
      setPaymentAmounts((prev) => ({ ...prev, [requestId]: '' }));
    }
  };

  const handleInputChange = (requestId, value) => {
    setPaymentAmounts((prev) => ({ ...prev, [requestId]: value }));
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const filteredRequests = requests.filter((request) => {
    if (tabIndex === 0) return request.status === 'Pending';
    if (tabIndex === 1) return request.status === 'Approved';
    if (tabIndex === 2) return request.status === 'Paid';
    return true;
  });

  return (
    <div>
      <Typography variant="h4" align="center" gutterBottom>
         CME Registrations
      </Typography>
      
      <Tabs value={tabIndex} onChange={handleTabChange} centered>
        <Tab label="Requests" />
        <Tab label="Approved" />
        <Tab label="Paid" />
      </Tabs>

      <Box p={3}>
        {filteredRequests.map((request) => (
          <Box key={request.id} className="request-card">
            <Typography variant="h6">{request.hospitalName}</Typography>
            <Typography><strong>Doctor:</strong> Dr. {request.doctor}</Typography>
            <Typography><strong>Hospital:</strong> {request.hospital}</Typography>
            <Typography><strong>Email:</strong> {request.email}</Typography>
            <Typography><strong>Contact:</strong> {request.phone}</Typography>
            <Typography><strong>Subject:</strong> {request.subject}</Typography>
            {request.status == "Paid"?
             <> <Typography><strong>paymentAmount:</strong> {request.paymentAmount}</Typography>
              <Typography><strong>Transaction ID:</strong> {request.paymentDetails}</Typography></>:
              <></>
            }
            {tabIndex === 0 && (
              <>
                <TextField
                  type="number"
                  label="Add Payment Amount"
                  variant="outlined"
                  value={paymentAmounts[request.id] || ''}
                  onChange={(e) => handleInputChange(request.id, e.target.value)}
                  size="small"
                  style={{ marginTop: '10px', marginRight: '10px' }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleAcceptRequest(request.id)}
                >
                  Accept Request
                </Button>
              </>
            )}
          </Box>
        ))}
      </Box>
    </div>
  );
}

export default CMERequest;
