import React, { useEffect, useState } from 'react';
import { db, storage } from '../firebase';
import { collection, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import './UpcomingEventAdmin.css';

const UpcomingEventAdmin = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [newEvent, setNewEvent] = useState({
    eventName: '',
    posterUrl: '',
    googleFormLink: '',
    date: '',
    time: '',
    location: '',
    description: '',
  });

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    setLoading(true);
    const eventCollection = collection(db, 'upcomingEvents');
    const eventSnapshot = await getDocs(eventCollection);
    const eventList = eventSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setEvents(eventList);
    setLoading(false);
  };

  const deleteEvent = async (id, url) => {
    await deleteDoc(doc(db, 'upcomingEvents', id));
    const imageRef = ref(storage, url);
    await deleteObject(imageRef);
    fetchEvents();
  };

  const uploadImage = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setUploading(true);
    const storageRef = ref(storage, `upcomingEvents/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        console.error('Upload failed:', error);
        setUploading(false);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        setNewEvent((prevEvent) => ({ ...prevEvent, posterUrl: downloadURL }));
        setUploading(false);
      }
    );
  };

  const addEvent = async (e) => {
    e.preventDefault();
    if (
      newEvent.eventName &&
      newEvent.posterUrl &&
      newEvent.googleFormLink &&
      newEvent.date &&
      newEvent.time &&
      newEvent.location &&
      newEvent.description
    ) {
      await addDoc(collection(db, 'upcomingEvents'), newEvent);
      setNewEvent({
        eventName: '',
        posterUrl: '',
        googleFormLink: '',
        date: '',
        time: '',
        location: '',
        description: '',
      });
      fetchEvents();
    }
  };

  return (
    <div className="upcoming-event-admin">
      <h2>Manage Upcoming Events</h2>
      <form onSubmit={addEvent} className="event-form">
        <input
          type="text"
          placeholder="Event Name"
          value={newEvent.eventName}
          onChange={(e) => setNewEvent({ ...newEvent, eventName: e.target.value })}
          required
        />
        <input type="file" onChange={uploadImage} disabled={uploading} />
        <input
          type="url"
          placeholder="Google Form Link"
          value={newEvent.googleFormLink}
          onChange={(e) => setNewEvent({ ...newEvent, googleFormLink: e.target.value })}
          required
        />
        <input
          type="date"
          placeholder="Event Date"
          value={newEvent.date}
          onChange={(e) => setNewEvent({ ...newEvent, date: e.target.value })}
          required
        />
        <input
          type="time"
          placeholder="Event Time"
          value={newEvent.time}
          onChange={(e) => setNewEvent({ ...newEvent, time: e.target.value })}
          required
        />
        <input
          type="text"
          placeholder="Location"
          value={newEvent.location}
          onChange={(e) => setNewEvent({ ...newEvent, location: e.target.value })}
          required
        />
        <textarea
          placeholder="Event Description"
          value={newEvent.description}
          onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })}
          required
        />
        <button type="submit" disabled={uploading}>Add Event</button>
      </form>
      {loading ? (
        <div className="loading-spinner">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="event-grid">
          {events.map((event) => (
            <div key={event.id} className="event-item">
              <h3>{event.eventName}</h3>
              <img src={event.posterUrl} alt="Event Poster" />
              <p><strong>Date:</strong> {event.date}</p>
              <p><strong>Time:</strong> {event.time}</p>
              <p><strong>Location:</strong> {event.location}</p>
              <p><strong>Description:</strong> {event.description}</p>
              <a href={event.googleFormLink} target="_blank" rel="noopener noreferrer">
                Google Form
              </a>
              <button className="delete-button" onClick={() => deleteEvent(event.id, event.posterUrl)}>Delete</button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UpcomingEventAdmin;
