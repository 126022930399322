// src/layouts/AdminLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import AdminSidebar from '../Admin/AdminSidebar';
import './AdminLayout.css'

const AdminLayout = () => {
  return (
    <div className="admin-container">
      <AdminSidebar />
      <div className="main-content">
        <Outlet />
      </div>
    </div>
  );
};

export default AdminLayout;
