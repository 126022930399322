import { Route, Routes, Navigate } from 'react-router-dom';


import AdminPage from './AdminPage/AdminPage';
import AdminLayout from './layouts/AdminLayouts';
import ActivityAdmin from './Admin/ActivityAdmin';
import AdvertisementAdmin from './Admin/AdvertisementAdmin';
import DonationAdmin from './Admin/DonationAdmin';
import UpcomingEventAdmin from './Admin/UpcomingEventAdmin';
import UserAdmin from './Admin/UserAdmin';
import CMERequest from "./Admin/CMERegistration/CMERequest"
import './App.css'
function App() {
  return (
    <Routes>
    <Route element={<AdminLayout />}>
        <Route path="/" element={< UserAdmin/>} />
        <Route path="/activity" element={< ActivityAdmin/>} />
        <Route path="/Advertisement" element={<AdvertisementAdmin/>}/>
        <Route path="/Donation" element={<DonationAdmin/>}/>
        <Route path="/UpcomingEvent" element={<UpcomingEventAdmin/>}/>
        <Route path="/CMErequests" element={<CMERequest/>}/>

          
        </Route>
        </Routes>
  );
}

export default App;
